.header_wrapper {
  /*Такой показатель был у styled компонента хэдер, а у футера было просто 0 32рх*/
  padding: var(--web-sides-padding);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}

.header_right_items {
  min-width: 32px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}

.chip {
  align-self: stretch;
  background: var(--dieDefault);
  color: var(--textBodyAdd);
  user-select: none;
  min-height: 100%;
  border-radius: 40px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.label {
  flex: 1;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: var(--textBodyMain);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pay_info_chip_span {
  overflow: hidden;
  white-space: nowrap;
}

.received_info_chip_span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90px;
}

@media (max-width: 399px) {
  .header_wrapper {
    padding: 32px var(--mobile-sides-padding);
    gap: 8px;
  }
}
